import React from 'react';
import { Link } from 'gatsby';
import fb from '../images/Facebook.png'
import tw from '../images/Twitter.png'
import ins from '../images/Instagram.png'
import Logo from './Logo';

export default function Nav(props) {

    //state
    const [showmenu, setShowMenu]=React.useState(false);

    const handleClick=function(){
        setShowMenu((prevstate)=>{
            return(!prevstate);
        });
    }

  return (
      <main>
        {/* Top - obsolete */}
        <div id="top-menu" className='hidden relative bg-orange-400'>
            {/* Top menu for default, sm & md */}
            <div className=' w-11/12 mx-auto py-1 flex items-center space-x-2 sm:w-8/12 sm:justify-between lg:hidden'>
                <div id="the svg">
                    <svg className='h-6 w-6' viewBox="0 0 40.939 69.855" xmlns="http://www.w3.org/2000/svg">
                    <path d="M126.062 58.005s-12.171-.78-15.748 2.796a9.16 9.16 0 0 0 12.953 12.952c3.576-3.576 2.795-15.748 2.795-15.748zm23.128-.835c-1.749.432-3.281 1.07-4.22 2.01a9.16 9.16 0 0 0 12.951 12.953c3.028-3.028 2.933-12.213 2.833-14.962h-11.563zm-24.02 24.277a9.158 9.158 0 0 0 0 12.952 9.158 9.158 0 0 0 12.952 0c3.577-3.576 2.796-15.748 2.796-15.748s-12.17-.78-15.748 2.796zm67.333-77.259c0-1.614-4.028-2.752-4.372-4.262-.354-1.56 2.773-4.33 2.094-5.737-.69-1.43-4.813-.708-5.793-1.936-.985-1.234.631-5.092-.603-6.078-1.227-.98-4.63 1.45-6.058.76-1.407-.678-1.622-4.859-3.181-5.214-1.51-.343-3.512 3.323-5.127 3.323-1.614 0-3.617-3.666-5.126-3.323-1.559.355-1.774 4.536-3.18 5.215-1.43.69-4.832-1.741-6.06-.76-.564.45-.531 1.504-.425 2.632a31.094 31.094 0 0 1 4.458 5.009c2.672-2.663 6.338-4.327 10.334-4.327 8.01 0 14.699 6.688 14.699 14.699 0 1.606-.273 3.157-.768 4.614a26.247 26.247 0 0 1 3.359 2.57 25.66 25.66 0 0 1 3.04 3.273 1.13 1.13 0 0 0 .432-.457c.679-1.406-2.449-4.178-2.094-5.737.343-1.511 4.37-2.65 4.37-4.264zM166.735 8.05a20.9 20.9 0 0 0-9.623 2.336c.003-.158.006-.317.006-.476 0-14.241-11.545-25.787-25.787-25.787-13.962 0-25.332 11.098-25.771 24.955-8.418 2.736-14.505 10.64-14.505 19.97 0 11.596 9.401 20.998 20.998 20.998h54.682c11.597 0 20.998-9.402 20.998-20.998 0-11.597-9.401-20.999-20.998-20.999z" fill="#15803d"/>
                    <path d="M20.47 0C9.183 0 0 9.183 0 20.47a20.282 20.282 0 0 0 2.065 8.97l15.466 29.945a3.308 3.308 0 0 0 5.877 0L38.87 29.448a20.28 20.28 0 0 0 2.07-8.978C40.939 9.183 31.756 0 20.469 0Zm0 31.184c-5.908 0-10.715-4.806-10.715-10.714S14.562 9.756 20.47 9.756c5.908 0 10.714 4.806 10.714 10.714S26.378 31.184 20.47 31.184zm7.885 34.702h-15.77a1.984 1.984 0 1 0 0 3.969h15.77a1.984 1.984 0 1 0 0-3.969z" fill="#fff"/>
                    </svg>
                </div>
                <div id="the location">
                <p className='text-rt text-gray-100 tracking-tight'>Kakungulu Road, Minister’s Village, Ntinda Kampala – Uganda</p>
                </div>
            </div>

            {/* Top menu for lg + */}
            <div id="location + socials" className='hidden lg:block lg:flex items-center justify-between px-20'>
                <div id="location">
                    <div className='py-1 flex items-center space-x-4'>
                        <div id="the svg">
                            <svg className='h-6 w-6' viewBox="0 0 40.939 69.855" xmlns="http://www.w3.org/2000/svg">
                            <path d="M126.062 58.005s-12.171-.78-15.748 2.796a9.16 9.16 0 0 0 12.953 12.952c3.576-3.576 2.795-15.748 2.795-15.748zm23.128-.835c-1.749.432-3.281 1.07-4.22 2.01a9.16 9.16 0 0 0 12.951 12.953c3.028-3.028 2.933-12.213 2.833-14.962h-11.563zm-24.02 24.277a9.158 9.158 0 0 0 0 12.952 9.158 9.158 0 0 0 12.952 0c3.577-3.576 2.796-15.748 2.796-15.748s-12.17-.78-15.748 2.796zm67.333-77.259c0-1.614-4.028-2.752-4.372-4.262-.354-1.56 2.773-4.33 2.094-5.737-.69-1.43-4.813-.708-5.793-1.936-.985-1.234.631-5.092-.603-6.078-1.227-.98-4.63 1.45-6.058.76-1.407-.678-1.622-4.859-3.181-5.214-1.51-.343-3.512 3.323-5.127 3.323-1.614 0-3.617-3.666-5.126-3.323-1.559.355-1.774 4.536-3.18 5.215-1.43.69-4.832-1.741-6.06-.76-.564.45-.531 1.504-.425 2.632a31.094 31.094 0 0 1 4.458 5.009c2.672-2.663 6.338-4.327 10.334-4.327 8.01 0 14.699 6.688 14.699 14.699 0 1.606-.273 3.157-.768 4.614a26.247 26.247 0 0 1 3.359 2.57 25.66 25.66 0 0 1 3.04 3.273 1.13 1.13 0 0 0 .432-.457c.679-1.406-2.449-4.178-2.094-5.737.343-1.511 4.37-2.65 4.37-4.264zM166.735 8.05a20.9 20.9 0 0 0-9.623 2.336c.003-.158.006-.317.006-.476 0-14.241-11.545-25.787-25.787-25.787-13.962 0-25.332 11.098-25.771 24.955-8.418 2.736-14.505 10.64-14.505 19.97 0 11.596 9.401 20.998 20.998 20.998h54.682c11.597 0 20.998-9.402 20.998-20.998 0-11.597-9.401-20.999-20.998-20.999z" fill="#15803d"/>
                            <path d="M20.47 0C9.183 0 0 9.183 0 20.47a20.282 20.282 0 0 0 2.065 8.97l15.466 29.945a3.308 3.308 0 0 0 5.877 0L38.87 29.448a20.28 20.28 0 0 0 2.07-8.978C40.939 9.183 31.756 0 20.469 0Zm0 31.184c-5.908 0-10.715-4.806-10.715-10.714S14.562 9.756 20.47 9.756c5.908 0 10.714 4.806 10.714 10.714S26.378 31.184 20.47 31.184zm7.885 34.702h-15.77a1.984 1.984 0 1 0 0 3.969h15.77a1.984 1.984 0 1 0 0-3.969z" fill="#fff"/>
                            </svg>
                        </div>
                        <div id="the location">
                        <p className='text-xs text-gray-100 tracking-tight'>Kakungulu Road, Minister’s Village, Ntinda Kampala – Uganda</p>
                        </div>
                    </div>
                </div>
                <div id="the socials" className='flex items-center space-x-1'>
                    <Link to="#"><div id="fb"><img src={fb} alt="Facebook/Meta logo" className='h-6 w-6'/></div></Link>
                    <Link to="#"><div id="tw"><img src={tw} alt="Twitter logo" className='h-6 w-6'/></div></Link>
                    <Link to="#"><div id="in"><img src={ins} alt="Instagram logo" className='h-6 w-6'/></div></Link>
                </div>               
            </div>

        </div>

      {/* Middle */}
      {/* default, sm & md middle */} 
        <div id="logo + burger" className='pt-4 pb-10 w-11/12 mx-auto flex items-center justify-between sm:w-10/12 lg:hidden'>
            <div id="logo">
                <Link to="/">
                    <svg className="w-24 lg:w-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.79913 23.628">
                        <path fill="#333" d="M-8.063089 87.24371h155.646962c1.01812 0 1.83833.83361 1.83833 1.86725v57.6199c0 1.03329-.82021 1.86725-1.83833 1.86725H-8.063089c-1.018117 0-1.837972-.83396-1.837972-1.86725v-57.6199c0-1.03364.819855-1.86725 1.837972-1.86725z"/>
                        <path fill="#fff" d="M43.982921 128.97873v-23.45266h9.788172c1.814689 0 3.202517.19297 4.161719.57608.957792.38312 1.73355 1.0982 2.320573 2.14419.587022 1.03469.881944 2.2987.881944 3.79095 0 1.30069-.226131 2.42675-.675217 3.37608-.449439.93839-1.0668 1.70074-1.853494 2.28776-.500239.37324-1.189214.68192-2.061633.92745.698852.28787 1.211439.57574 1.529644.86396.217664.19156.527756.60219.933097 1.2319.415925.62829.692856 1.11336.830792 1.45521l2.838803 6.79908h-6.636809l-3.137605-7.16703c-.398286-.92745-.752475-1.52965-1.063978-1.80799-.42298-.36089-.903816-.54433-1.438628-.54433h-.51823v9.51935zm5.89915-13.94989h2.4765c.267405 0 .786694-.10724 1.55575-.31962.38735-.0963.704144-.34184.945444-.73589.250825-.39405.376061-.84878.376061-1.35996 0-.75812-.19438-1.33808-.582789-1.74343-.388761-.4064-1.120422-.60889-2.191102-.60889H49.88207Zm14.502694-9.50277h8.724194c1.719792 0 3.10762.28927 4.16172.86395 1.063977.57608 1.940277 1.403 2.632075 2.47932.691797 1.07738 1.192036 2.33151 1.503538 3.76061.310092 1.42875.467078 2.94217.467078 4.54202 0 2.50789-.234244 4.45381-.701322 5.83988-.456142 1.37548-1.097139 2.53294-1.916994 3.47133-.821267.92745-1.703211 1.54623-2.645834 1.85632-1.288344.42721-2.454275.63923-3.500261.63923h-8.724194zm5.871633 5.31107v12.81429h1.440039c1.226608 0 2.099028-.16404 2.618317-.496.51823-.34043.92463-.92745 1.219552-1.76001.293512-.84173.440973-2.20204.440973-4.07882 0-2.48461-.330553-4.18641-.98672-5.10293-.657225-.91792-1.744486-1.37653-3.267075-1.37653zm24.370594 14.2695h-6.691489l-.919338 3.87209H81.01224l7.146219-23.45266h6.403975l7.14375 23.45266h-6.146095zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zm10.521244-14.50939h5.484636l7.156097 12.9741v-12.9741h5.535789v23.45266h-5.535789l-7.118702-12.87745v12.87745h-5.522031zm19.747442 0h17.849849v5.79049h-5.987697v17.66217h-5.874455v-17.66217h-5.987697z"/>
                        <path fill="#1e40af" d="M27.106033 105.34968h14.910505v5.48746H27.106033Z"/>
                        <path fill="#fa7c23" d="M27.242558 123.24221h14.911916v5.48887H27.242558Z"/>
                        <path fill="#15803d" d="M27.242558 114.4778h10.437283v5.48887H27.242558Z"/>
                        <path fill="#fff" d="m1.715558 105.52889 6.722181-.0649 8.437738 22.7778-6.895747.10865zm19.594336 3.77013 4.019902-3.30588-7.298266 19.7612-4.412544-11.0684z"/>
                        <path fill="#333" d="M42.26701 23.628V.17639h9.788525c1.814689 0 3.202516.19297 4.161366.57609.959203.38312 1.733903 1.0982 2.320925 2.14418.587022 1.03364.881945 2.29729.881945 3.79095 0 1.29964-.226131 2.42676-.674159 3.37609-.450497.93733-1.067858 1.69933-1.854552 2.28635-.500239.37465-1.188156.68333-2.061634.92745.699911.28928 1.211086.57715 1.529645.86537.217664.19155.527755.60219.934155 1.23189.414867.6283.691798 1.11337.829734 1.45521l2.838802 6.79803h-6.636808l-3.137605-7.16597c-.398286-.92746-.752475-1.52965-1.063978-1.80799-.42298-.3623-.902758-.54434-1.438628-.54434h-.51823v9.5183zm5.899503-13.94989h2.4765c.267052 0 .786694-.10619 1.55575-.31856.388408-.0963.704144-.3429.946502-.73589.249414-.39406.375003-.84879.375003-1.35996 0-.75812-.19438-1.33809-.582789-1.74308-.38876-.40675-1.120422-.60924-2.191103-.60924h-2.579863ZM62.668854.17639h8.724547c1.719792 0 3.107619.28928 4.161719.86396 1.063625.57608 1.940278 1.40299 2.632075 2.47791.691798 1.07879 1.192036 2.33292 1.503186 3.76061.311503 1.4291.467431 2.94216.467431 4.54201 0 2.50931-.234245 4.45382-.700264 5.8413-.457553 1.37548-1.098197 2.53294-1.918053 3.47133-.821266.92745-1.703211 1.54622-2.645833 1.85632-1.287286.42721-2.454275.63817-3.500261.63817h-8.724547zm5.871986 5.30966v12.81571h1.440039c1.226608 0 2.100086-.16404 2.618316-.49741.518231-.33902.924631-.92746 1.219553-1.7586.293511-.84314.440972-2.20204.440972-4.07882 0-2.48461-.329494-4.18782-.986719-5.10293-.657225-.91898-1.744486-1.37795-3.266017-1.37795zm24.370594 14.27092h-6.690431l-.920397 3.87103H79.29774L86.442901.17639h6.403622l7.14375 23.45161h-6.145742zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zM102.200778.17639h5.484636l7.156097 12.97411V.17639h5.535789V23.628h-5.535789l-7.117644-12.8778V23.628h-5.523089zm19.747441 0h17.850908v5.7905h-5.988755V23.628h-5.873397V5.96689h-5.988756z"/>
                        <path fill="#1e40af" d="M25.390474 0h14.910505v5.48746H25.390474Z"/>
                        <path fill="#fa7c23" d="M25.528058 17.89254h14.910505v5.48887H25.528058Z"/>
                        <path fill="#15803d" d="M25.528058 9.12813h10.437283V14.617H25.528058Z"/>
                        <path fill="#333" d="m0 .17922 6.72218-.0649 8.437739 22.7764-6.895747.11006zm19.594335 3.77013L23.615296.64347l-7.299324 19.7612-4.412545-11.0684z"/>
                    </svg>
                </Link>            
            </div>
            <div id="burger">
                <button onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg> 
                </button>                
            </div>
        </div>

        {/* lg + middle */}
        {/* <div>
            <h1 className='hidden lg:block pt-12 pb-14 text-center font-black text-worksans text-4xl'>{props.pagelabel}</h1>
        </div> */}

        {/* The Drop-down - for mobile, sm & md */}
        {showmenu && (<div className='absolute right-0 top-0 w-full z-10 text-center bg-verdantblack '>
            <div id="the button x" className='absolute right-3 top-6 sm:right-12 sm:top-5 md:right-14'>
                <button onClick={handleClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div> 

            
            <div id="" className='py-8 w-5/12 mx-auto text-left text-gray-100 font-worksans text-lg font-bold'>
                <Logo/>               
                <div id="orange line" className='my-4 mx-auto border-b-2 border-orange-500'></div>
                <div id="the links" className='text-center'>
                    <Link to="/about" className='focus:text-orange-400'><p>About</p></Link>
                    <Link to="/services" className='focus:text-orange-400'><p className='py-2'>Services</p></Link>
                    <Link to="/projects" className='focus:text-orange-400'><p>Projects</p></Link>
                    <Link to="/contact" className='focus:text-orange-400'><p className='pt-2'>Contact</p></Link>
                </div>
                
            </div>
        </div>)}

        {/* The menu - for lg +*/}
        <div className='hidden lg:block w-11/12 mx-auto lg:flex items-center justify-between'>
                <Link to="/">
                    <svg className="w-24 lg:w-40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.79913 23.628">
                        <path fill="#333" d="M-8.063089 87.24371h155.646962c1.01812 0 1.83833.83361 1.83833 1.86725v57.6199c0 1.03329-.82021 1.86725-1.83833 1.86725H-8.063089c-1.018117 0-1.837972-.83396-1.837972-1.86725v-57.6199c0-1.03364.819855-1.86725 1.837972-1.86725z"/>
                        <path fill="#fff" d="M43.982921 128.97873v-23.45266h9.788172c1.814689 0 3.202517.19297 4.161719.57608.957792.38312 1.73355 1.0982 2.320573 2.14419.587022 1.03469.881944 2.2987.881944 3.79095 0 1.30069-.226131 2.42675-.675217 3.37608-.449439.93839-1.0668 1.70074-1.853494 2.28776-.500239.37324-1.189214.68192-2.061633.92745.698852.28787 1.211439.57574 1.529644.86396.217664.19156.527756.60219.933097 1.2319.415925.62829.692856 1.11336.830792 1.45521l2.838803 6.79908h-6.636809l-3.137605-7.16703c-.398286-.92745-.752475-1.52965-1.063978-1.80799-.42298-.36089-.903816-.54433-1.438628-.54433h-.51823v9.51935zm5.89915-13.94989h2.4765c.267405 0 .786694-.10724 1.55575-.31962.38735-.0963.704144-.34184.945444-.73589.250825-.39405.376061-.84878.376061-1.35996 0-.75812-.19438-1.33808-.582789-1.74343-.388761-.4064-1.120422-.60889-2.191102-.60889H49.88207Zm14.502694-9.50277h8.724194c1.719792 0 3.10762.28927 4.16172.86395 1.063977.57608 1.940277 1.403 2.632075 2.47932.691797 1.07738 1.192036 2.33151 1.503538 3.76061.310092 1.42875.467078 2.94217.467078 4.54202 0 2.50789-.234244 4.45381-.701322 5.83988-.456142 1.37548-1.097139 2.53294-1.916994 3.47133-.821267.92745-1.703211 1.54623-2.645834 1.85632-1.288344.42721-2.454275.63923-3.500261.63923h-8.724194zm5.871633 5.31107v12.81429h1.440039c1.226608 0 2.099028-.16404 2.618317-.496.51823-.34043.92463-.92745 1.219552-1.76001.293512-.84173.440973-2.20204.440973-4.07882 0-2.48461-.330553-4.18641-.98672-5.10293-.657225-.91792-1.744486-1.37653-3.267075-1.37653zm24.370594 14.2695h-6.691489l-.919338 3.87209H81.01224l7.146219-23.45266h6.403975l7.14375 23.45266h-6.146095zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zm10.521244-14.50939h5.484636l7.156097 12.9741v-12.9741h5.535789v23.45266h-5.535789l-7.118702-12.87745v12.87745h-5.522031zm19.747442 0h17.849849v5.79049h-5.987697v17.66217h-5.874455v-17.66217h-5.987697z"/>
                        <path fill="#1e40af" d="M27.106033 105.34968h14.910505v5.48746H27.106033Z"/>
                        <path fill="#fa7c23" d="M27.242558 123.24221h14.911916v5.48887H27.242558Z"/>
                        <path fill="#15803d" d="M27.242558 114.4778h10.437283v5.48887H27.242558Z"/>
                        <path fill="#fff" d="m1.715558 105.52889 6.722181-.0649 8.437738 22.7778-6.895747.10865zm19.594336 3.77013 4.019902-3.30588-7.298266 19.7612-4.412544-11.0684z"/>
                        <path fill="#333" d="M42.26701 23.628V.17639h9.788525c1.814689 0 3.202516.19297 4.161366.57609.959203.38312 1.733903 1.0982 2.320925 2.14418.587022 1.03364.881945 2.29729.881945 3.79095 0 1.29964-.226131 2.42676-.674159 3.37609-.450497.93733-1.067858 1.69933-1.854552 2.28635-.500239.37465-1.188156.68333-2.061634.92745.699911.28928 1.211086.57715 1.529645.86537.217664.19155.527755.60219.934155 1.23189.414867.6283.691798 1.11337.829734 1.45521l2.838802 6.79803h-6.636808l-3.137605-7.16597c-.398286-.92746-.752475-1.52965-1.063978-1.80799-.42298-.3623-.902758-.54434-1.438628-.54434h-.51823v9.5183zm5.899503-13.94989h2.4765c.267052 0 .786694-.10619 1.55575-.31856.388408-.0963.704144-.3429.946502-.73589.249414-.39406.375003-.84879.375003-1.35996 0-.75812-.19438-1.33809-.582789-1.74308-.38876-.40675-1.120422-.60924-2.191103-.60924h-2.579863ZM62.668854.17639h8.724547c1.719792 0 3.107619.28928 4.161719.86396 1.063625.57608 1.940278 1.40299 2.632075 2.47791.691798 1.07879 1.192036 2.33292 1.503186 3.76061.311503 1.4291.467431 2.94216.467431 4.54201 0 2.50931-.234245 4.45382-.700264 5.8413-.457553 1.37548-1.098197 2.53294-1.918053 3.47133-.821266.92745-1.703211 1.54622-2.645833 1.85632-1.287286.42721-2.454275.63817-3.500261.63817h-8.724547zm5.871986 5.30966v12.81571h1.440039c1.226608 0 2.100086-.16404 2.618316-.49741.518231-.33902.924631-.92746 1.219553-1.7586.293511-.84314.440972-2.20204.440972-4.07882 0-2.48461-.329494-4.18782-.986719-5.10293-.657225-.91898-1.744486-1.37795-3.266017-1.37795zm24.370594 14.27092h-6.690431l-.920397 3.87103H79.29774L86.442901.17639h6.403622l7.14375 23.45161h-6.145742zm-1.2319-5.07118-2.100086-8.42962-2.086328 8.42962zM102.200778.17639h5.484636l7.156097 12.97411V.17639h5.535789V23.628h-5.535789l-7.117644-12.8778V23.628h-5.523089zm19.747441 0h17.850908v5.7905h-5.988755V23.628h-5.873397V5.96689h-5.988756z"/>
                        <path fill="#1e40af" d="M25.390474 0h14.910505v5.48746H25.390474Z"/>
                        <path fill="#fa7c23" d="M25.528058 17.89254h14.910505v5.48887H25.528058Z"/>
                        <path fill="#15803d" d="M25.528058 9.12813h10.437283V14.617H25.528058Z"/>
                        <path fill="#333" d="m0 .17922 6.72218-.0649 8.437739 22.7764-6.895747.11006zm19.594335 3.77013L23.615296.64347l-7.299324 19.7612-4.412545-11.0684z"/>
                    </svg>
                </Link>           
                <nav className='pl-10 pr-4 py-2 lg:flex items-center space-x-10 justify-between bg-green-600 rounded-md uppercase text-sm text-gray-100 font-bold'>
                {/* <Link to="/"><p>Home</p></Link> */}
                <Link to="/about"><p>About</p></Link>
                <Link to="/services"><p>Services</p></Link>
                <Link to="/projects"><p>Projects</p></Link>
                <Link to="/contact"><p className='ml-2 px-3 py-1 bg-verdantblue rounded-md '>Contact</p></Link>
            </nav>
        </div>
        

      </main>
  )
}
