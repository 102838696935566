import React from 'react';
import ProjectNav from '../../components/ProjectNav'
import Footer from '../../components/Footer';
import {graphql } from 'gatsby';
import { Link } from 'gatsby';
import AOS from 'aos';
import "aos/dist/aos.css";

export default function ProjectTemplate({data}) {
    // initializing the AOS
    React.useEffect(()=>{
      AOS.init({
        delay: 100,
        duration: 500,
      });
      AOS.refresh();
    });
    
  return (
      <main>
        <div className='w-11/12 mx-auto lg:pt-10 lg:pb-20'><ProjectNav/></div>
        {/* <div id="label" className='py-1 absolute top-24 inset-x-0 w-7/12 mx-auto text-center text-white rounded-md bg-green-600 font-bold text-3xl font-worksans lg:hidden sm:w-6/12'>Project</div> */}
            <section data-aos="fade-right" className='w-11/12 mx-auto mt-1 py-12 bg-gray-100 rounded-lg shadow-sm sm:w-8/12 lg:w-10/12 lg:py-32 xl:w-9/12'>
                <div id="container" className='w-10/12 mx-auto lg:flex lg:justify-between lg:space-x-8'>
                    <div id="image" className='lg:w-8/12'>
                        <img src={data.contentfulProject.projectImage.file.url} className='w-full h-40 object-cover rounded-lg lg:h-full' alt={data.contentfulProject.projectTitle}/>
                    </div>
                    <div id="the content" className='lg:w-7/12'>
                        <h2 className='py-8 text-xl font-bold text-orange-500 font-worksans lg:pt-0 lg:pb-8'>{data.contentfulProject.projectTitle}</h2>
                        <div id="the green line" className='border-b-2 border-green-500'></div>
                        <div id="the lower content" className='uppercase text-sm font-bold text-orange-400'>
                            <p className='my-6 py-3 px-2 rounded-md bg-gray-200'>location: <span className='text-gray-600'>{data.contentfulProject.projectLocation}</span></p>
                            <p>Date: <span className='text-gray-600'>{data.contentfulProject.projectDuration}</span></p>
                            <p className='my-6 py-3 px-2 rounded-md bg-gray-200'>Client, Funder &amp; Contractor:<br/> <span className='text-gray-600'>{data.contentfulProject.fundedBy}</span></p>
                            <p>Role:</p>
                            <p className='text-gray-600 normal-case'>{data.contentfulProject.roleOnProject}</p>
                        </div>
                        
                    </div>
                </div>
            </section>

            <Link to="/projects"><div id="cta - back to projects" className='my-10 px-6 py-1 w-8/12 mx-auto flex items-center space-x-4 uppercase font-ibmplex text-sm font-bold text-gray-100 bg-green-700 rounded-md shadow-lg sm:w-6/12 sm:flex sm:justify-center lg:w-4/12 lg:px-2 lg:my-16 xl:w-3/12'>
                <div id="arrow back">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-orange-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                    </svg>
                </div>
                <div className='sm:text-center'>Back to projects</div>
            </div></Link>

        <footer><Footer/></footer>

      </main>
  );
}

export const query = graphql`
query ($id: String) {
    contentfulProject(id: {eq: $id}) {
      projectImage {
        file {
          url
        }
      }
      projectTitle
      projectLocation
      projectDuration
      fundedBy
      roleOnProject
    }
  }
  `